.shadow-inner-custom {
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.navbar-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #2b00ac;
  transition: color 0.3s ease;
  z-index: 10;
}

.navbar-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2.5px;
  width: 100%;
  height: 1px;
  background-color: black;
  transition: width 0.3s ease;
}

.navbar-link:active {
  color: #b0b0b0;
}

.footer-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;
  z-index: 9;
}

.footer-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2.5px;
  width: 100%;
  height: 1px;
  background-color: black;
  transition: width 0.3s ease;
}

.footer-link:active {
  color: #b0b0b0;
}

.footer {
  background-color: transparent;
}

.wouessi-background {
  position: fixed;
  inset: 0px;
  width: 100%;
  z-index: 1;
}

.navbar {
  position: relative;
  z-index: 100;
}

.page-background {
  position: relative;
  background-color: #f4f4f4;
  z-index: 10;
  min-height: 40vw;
}

.navbar-slider {
  z-index: 11; /*should greater than page background */
}

@media (max-width: 450px) {
  .page-background {
    position: relative;
    background-color: #f4f4f4;
    z-index: 10;
    min-height: 50vw;
  }
}

.no-scroll {
  overflow: hidden;
}

.Returnbtn {
  width: 14vw;
  height: 14vw;
  align-self: center;
  margin: auto;
  cursor: pointer;
}
.Returnbtn:hover {
  background: white;
}
